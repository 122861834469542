<template>
  <el-dialog title="功能模块设置" width="600px" @open="initForm()" :visible.sync="visible">
    <div class="flex">
      <div>
        <h4>
          <el-checkbox v-model="treeCheck" @change="allCheck($event,'tree')">
            <span class="bold">管理平台</span>
          </el-checkbox>
        </h4>
        <el-tree ref="tree" :default-expanded-keys="[0]" node-key="id" :props="{label:'name'}" :data="tree" show-checkbox></el-tree>
      </div>
      <div>
        <h4>
          <el-checkbox v-model="appTreeCheck" @change="allCheck($event,'appTree')">
            <span class="bold">App</span>
          </el-checkbox>
        </h4>
        <el-tree ref="appTree" :default-expanded-keys="[0]" node-key="id" :props="{label:'name'}" :data="appTree" show-checkbox></el-tree>
      </div>

    </div>
    <div slot="footer">
      <el-button size="small" @click="$emit('update:show', false)">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="commit" size="small">确 定</el-button>
    </div>
  </el-dialog>

</template>
<script>
export default {

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    //当前选择编辑or修改的数据
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    moduleIds: {
      type: Array,
      default() {
        return []
      }
    },
    appModuleIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      appTree: [],
      tree: [],
      loading: false,
      treeCheck: false,
      appTreeCheck: false
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },

  },
  watch: {},

  created() {
    this.getModule();
  },
  mounted() { },
  methods: {
    // 树全选、取消全选
    allCheck(bol, treeType) {
      console.log(bol, treeType)
      this.$nextTick(() => {
        if (bol) this.$refs[treeType].setCheckedNodes(this[treeType]);
        if (!bol) this.$refs[treeType].setCheckedNodes([])
      })
    },
    // 初始化表单
    initForm() {
      this.treeCheck = false;
      this.appTreeCheck = false;
      console.log(this.$refs.tree, this.ids)

      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
        this.moduleIds.forEach((element) => {
          var node = this.$refs.tree.getNode(element);
          if (node && node.isLeaf) {
            this.$refs.tree.setChecked(node, true);
          }
        })
        this.$refs.appTree.setCheckedKeys([]);
        this.appModuleIds.forEach((element) => {
          var node = this.$refs.appTree.getNode(element);
          if (node && node.isLeaf) {
            this.$refs.appTree.setChecked(node, true);
          }
        })
      })
    },
    // 获取菜单树
    async getModule() {
      this.$get("/platform-config/community/modulemenu/tree").then((res) => {
        if (res && res.code == 0) {
          this.tree = res.data;
          this.tree.forEach((v) => {
            v.name = v.moduleName;
            v.children = v.menuVOs;
          });
        }
      });
      this.$get("/platform-config/community/appmenu/tree").then((res) => {
        if (res && res.code == 0) {
          this.appTree = res.data;
        }
      });
    },
    // 提交
    commit() {
      var modules = this.$refs.tree.getCheckedNodes(false, true);
      var moduleIds = modules.map((item) => {
        return item.id;
      });
      var appModules = this.$refs.appTree.getCheckedNodes(false, true);
      var appModuleIds = appModules.map((item) => {
        return item.id;
      });
      this.$emit('update:moduleIds', moduleIds);
      this.$emit('update:appModuleIds', appModuleIds);
      this.$emit('confirm',{moduleIds,appModuleIds})
      this.$emit('update:show', false);
    },
  },
};
</script>
<style scoped>
.flex {
  display: flex;
}
.flex > div {
  flex: 1;
}
.flex h4 {
  padding-left: 22px;
  margin-bottom: 10px;
}
.bold {
  font-weight: 800;
}
</style>

